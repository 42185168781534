.security-header {
    margin:0;
    padding:0;
}
.security-header-middle {
    margin:0;
    padding:0;
	margin-bottom:15px;
}
.security-content {
    margin:0;
    padding:0;
}
.security-content-middle {
	box-shadow: 0 2px 2px #cccccc;
    padding:20px;
    margin:auto;
}
.security-content-middle form div {
	margin-bottom: 15px;
}
.security-content .form-group-fields.register-checkbox {
	margin-left: 0;
	padding-left: 0;
}
.security-content .register-checkbox label:last-child {
	margin-left: 15px;
}
.security-footer {
	margin:0;
    padding:0;
}

.security-footer-middle {
	margin:0;
    padding:0;
	margin-top:15px;
}

.security-dashboard-footer {
    background:#a5a4a4;
    padding:15px;
}

.security-admin-footer {
    background:#7d97af;
    padding:15px;
}

#CCCLoginForm_LoginForm .btn-toolbar .action {
    float:left;
    margin:0;
}

#CCCLoginForm_LoginForm .btn-toolbar .login-cancel {
    margin-left:30px;
    display: inline-block;
}

#CCCLoginForm_LoginForm_error .bad {
    color:#ff0000;
}

#LostPasswordForm_lostPasswordForm .btn-toolbar .action {
    float:left;
    margin:0;
}

#Form_RegisterForm .btn-toolbar .action {
    float:left;
    margin:0;
}
.btn-primary:hover, .btn-primary:focus {
    background-color: #000000;
    color: #ffffff;
}
.btn-primary:active {
    background-color: #6d6e71;
    color: #ffffff;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #000000;
}

/**
* Global dashboard registration
*/
.security-dashboard .security-admin {
    width:100%;
    display:inline-block;
}

.security-dashboard .security-logo-container {
    position: relative;
    text-align: center;
    color: white;
}

.security-dashboard .security-logo-container .top-left-text {
    position: absolute;
    top: 30px;
    left: 16px;
    color: white;
    font-size: xx-large;
}
.security-dashboard .security-logo-container .top-left-text a {
    color: white;
}
.security-dashboard a:hover {
    text-decoration:underline;
}
.security-dashboard a {
    color: #c83b20 !important;
    font-weight: 600;
    text-decoration: none;
}
.security-dashboard .btn-primary {
    background-color: #c83b20 !important;
    border-color: #c83b20 !important;
    color: #ffffff;
}
.security-dashboard .btn-primary a {
    color: #ffffff !important;
    text-decoration: none;
}
.security-dashboard .bad, .security-dashboard .required, .security-dashboard .validation {
    color: #c83b20;
}
.security-dashboard .required-mark:after {
    color: #c83b20;
    content: " *";
    font-size: 100%;
    position: relative;
    left: 5px;
}
.security-dashboard .security-content .register-checkbox {
	margin-left:20px;
}
.security-dashboard .security-content .form-group-fields {
	margin-bottom:15px !important;
}
.security-dashboard .security-content .do-cancel {
    margin-left:30px;
    display: inline-block;
    color: #c83b20 !important;
    font-weight: 600;
    text-decoration: none;
}
.security-dashboard .security-content .error{
      color:#c83b20;
}
.security-dashboard .security-content input[type=submit], .security-dashboard input[type=reset], .security-dashboard input.reset {
    background-color: #c83b20;
    color:#ffffff;
    border:1px #c83b20 solid;
    border-radius: 0px 3px 3px;
    width: auto;
}
.security-dashboard .security-content input[type=submit]:active, .security-dashboard input[type=reset]:active, .security-dashboard input.reset:active {
    background-color: #6d6e71;
    border:1px #6d6e71 solid;
}